// src/components/common/Logo.jsx
import logo from '../../assets/logo.png';


import './Logo.scss';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to='/' className="logo">
      <img src={logo} alt="FreeSpinMaple" />
    </Link>
  );
};

export default Logo;