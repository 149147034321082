// src/components/layout/Footer/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import footer1 from '../../../assets/images/begambleaware.png';
import footer2 from '../../../assets/images/gambleaware.png';
import footer3 from '../../../assets/images/gamstop.png';
import footer4 from '../../../assets/images/18-plus.png';
import './Footer.scss';

const Footer = () => {
  const certifications = [


    { src: footer1, alt: 'Be Gamble Aware' },
    { src: footer2, alt: 'Gamble Aware' },
    { src: footer3, alt: 'GamStop' },
    { src: footer4, alt: '18 Plus' },
  ];

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          {/* Certifications */}
          <div className="footer__certifications">
            {certifications.map((cert, index) => (
              <img 
                key={index}
                src={cert.src}
                alt={cert.alt}
                className="footer__cert-image"
              />
            ))}
          </div>

          {/* Disclaimer */}
          <div className="footer__disclaimer">
            <p>
            Disclaimer: FreeSpinMaple.Com is a most excellent social casino gaming destination that gives a 
              comprehensive suite of free-to-play entertainment alternatives. Our platform features  
              good sized series of digital casino games, inclusive of famous titles like Jackpot Pearl, 
              Max the Winner, and Hidden Treasures of Rome, all designed purely for leisure 
              functions. We emphasize that each one credits, rewards, and in-sport currencies available on our 
              platform are digital and deliver really no actual-international economic price. Players need to 
              understand that achievement in our social casino video games does now not expect or assure achievement 
              in actual-money playing sports. In compliance with Canadian gaming regulations, our 
              platform is strictly restricted to users who are 19 years of age or older. By getting access to and 
              using our offerings, you explicitly renowned and confirm that you meet the minimal age 
              requirement and absolutely recognize that no actual money gambling activities take location in this 
              platform. We maintain a sturdy commitment to responsible gaming practices and feature 
              carried out diverse tools and capabilities to make certain a secure, controlled, and interesting 
              surroundings for our community. Our platform operates with entire transparency, adhering 
              to strict protection protocols and enterprise excellent practices to protect our users whilst they 
              experience our social gaming experience.
            </p>
          </div>

          {/* Contact Info */}
          <div className="footer__info">
            <h3 className="footer__info-title">CONTACT INFO</h3>
            
            <div className="footer__info-group">
              <h4>Location:</h4>
              <p>174 Maple Avenue Toronto, ON M5H 2N6 Canada</p>
            </div>

            <div className="footer__info-group">
              <h4>Email:</h4>
              <p>info@freespinmaple.com</p>
            </div>

            {/* Legal Links */}
            <div className="footer__links">
              <Link to="/terms" className="footer__link">TERMS OF SERVICE</Link>
              <Link to="/privacy" className="footer__link">PRIVACY POLICY</Link>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="footer__copyright">
          <p>© 2021 FreeSpinMaple.COM MADE BY <span>FreeSpinMaple.COM</span></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;